import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CLabelCard,
  CContactBox,
  CCard,
  AssetImage,
  CBtnList,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            en: <>party</>,
            ja: '宴会・パーティー',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/party/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/party/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '宴会・会議',
                path: '/banquet/',
              },
            ],
            current: {
              label: '宴会・パーティー',
            },
          }}
        />
      </CJumbotron>
      <div className="l_sect">
        <section className="u_mbExLarge">
          <LWrap>
            <h2 className="c_headingLv2">
              ご人数やシーンに合わせて
              <br />
              多種多様なパーティスタイルを提案
            </h2>
            <p className="c_sectLead">
              料理長が厳選した食材と調理法にこだわった15種類のコース・ブッフェメニューからお好みに合わせてお選びいただけます。
              <br />
              ごゆっくりとお料理をたのしんでいただけるように､一皿一皿に心を込めたコース仕立ての多彩なメニューをご提供いたします。
            </p>
            <CCard
              col={2}
              exClass="u_mbExLarge"
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/party/img_party.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/party/img_party02.png',
                    alt: '',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{
                ja: 'プラン',
                en: <>PLAN</>,
              }}
            />
            <div className="l_other">
              <CBtnList
                data={[
                  {
                    label: 'リゾートバンケットプラン',
                    link: {
                      href: '/assets/files/pdf/banquet_patry_plan.pdf',
                      blank: true,
                    },
                    icon: 'blank',
                    color: 'borderTheme',
                  },
                ]}
              />
            </div>
          </LWrap>
        </section>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{
                ja: 'その他',
                en: <>OTHER</>,
              }}
            />
            <div className="l_other">
              <CBtnList
                data={[
                  {
                    label: 'オプション',
                    link: {
                      href: '/assets/files/pdf/banquet_option.pdf',
                      blank: true,
                    },
                    icon: 'blank',
                    color: 'borderTheme',
                  },
                ]}
              />
              <CBtnList
                data={[
                  {
                    label: 'ファクトシート',
                    link: {
                      href: '/assets/files/pdf/banquet_factsheet.pdf',
                      blank: true,
                    },
                    icon: 'blank',
                    color: 'borderTheme',
                  },
                ]}
              />
            </div>
          </LWrap>
        </section>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{
                ja: '宴会場',
                en: <>BANQUET</>,
              }}
            />
            <CLabelCard
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet.png',
                    alt: 'ロイヤルホール',
                  },
                  label: '大宴会場',
                  title: <>ロイヤルホール</>,
                  text: <>ロビーフロア</>,
                  link: {
                    href: '/banquet/grand_hall/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet02.png',
                    alt: 'クラウンルーム',
                  },
                  label: '中宴会場',
                  title: <>クラウンルーム</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/crown/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet03.png',
                    alt: 'モンシェリー',
                  },
                  label: '中宴会場',
                  title: <>モンシェリー</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/mon_cheri/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet04.png',
                    alt: 'エクスクルーシブテーブル',
                  },
                  label: '小宴会場',
                  title: <>エクスクルーシブテーブル</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/exclusive_table/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet05.png',
                    alt: 'うたげ',
                  },
                  label: '小宴会場',
                  title: <>うたげ</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/utage/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet06.png',
                    alt: 'かすみ',
                  },
                  label: '小宴会場',
                  title: <>かすみ</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/utage#kasumi',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet07.png',
                    alt: 'あや',
                  },
                  label: '小宴会場',
                  title: <>あや</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/utage#aya',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="l_sect u_bgGray u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{
                ja: 'お問合せ',
                en: <>CONTACT</>,
              }}
            />
            <p className="c_sectLead u_mb30">
              条件や予算をお伺いし、最適なプランをご提案させていただきます。
            </p>
            <CContactBox
              color="white"
              tel={{
                number: '022-377-2100',
                note: <>＜受付時間＞ 平日　10:00～17:00</>,
              }}
              mail={{
                link: {
                  href: 'https://contact.royalparkhotels.co.jp/srph/contact',
                },
              }}
            />
          </LWrap>
        </section>
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
